import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Mencreditcard/First'
import Quickly from '../../Menhome/Quickly'

const CreditCard = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"Credit Card"} common={"Credit Card"}/>
        <First/>
        <Quickly/>
    </>
  )
}

export default CreditCard