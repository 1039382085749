import { Link}  from 'react-router-dom'
import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Mennews/First'

const NewsMain = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"News"} common={"News"}/>
        <First/>
    </>
  )
}

export default NewsMain