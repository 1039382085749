import React from 'react'
import { Link } from 'react-router-dom';

function First() {
    const creditCards = [
        {
            image: 'assets/images/credit-card/credit-card-1-1.png',
            title: 'Visa Platinum Card',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
        {
            image: 'assets/images/credit-card/credit-card-1-2.png',
            title: 'Visa Gold Card',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
        {
            image: 'assets/images/credit-card/credit-card-1-3.png',
            title: 'Visa Classic Card',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
        {
            image: 'assets/images/credit-card/credit-card-1-4.png',
            title: 'Titanium Mastercard',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
        {
            image: 'assets/images/credit-card/credit-card-1-5.png',
            title: 'Titanium Mastercard',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
        {
            image: 'assets/images/credit-card/credit-card-1-6.png',
            title: 'Express Card',
            description: 'There are many variations of passages of lorem ipsum available the majority have some.',
        },
    ];

    return (
        <>
            <section className="credit-card">
                <div className="container">
                    <div className="row">
                        {creditCards.map(card => (
                            <div className="col-lg-4 col-md-6" key={card.title}>
                                <div className="credit-card__box">
                                    <div className="credit-card__box-image">
                                        <img src={card.image} alt="" />
                                    </div>
                                    <div className="credit-card__content">
                                        <h3><Link to="/credit-card-details">{card.title}</Link></h3>
                                        <p>{card.description}</p>
                                        <Link to="/credit-card-details" className="credit-card__link"><i className="pylon-icon-right-arrow"></i></Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
