import React from "react"
import sridhar from '../../../assets/images/team/sridhar.jpg'
import '../../../assets/css/advisor.css'
import Bradcom from '../../Bradcom/Main'
function Advisors(){
return(
<>
        <Bradcom title={"Home"} subtitle={" Details"} common={"Advisors"}/>

<section className="team-details">
    <div className="container">
       
        <div className='col-lg-5'>
            <div className="block-title text-left">
                <h1>Meet our Team</h1>
                <p>Advisors</p>
            </div>
        </div>
        <div className="row" id='person1'>
            <div className="col-lg-5">
                <img src={sridhar} width="70%" height="400px" className="img-fluid rounded-image" alt="" />
            </div>

            <div className="col-lg-6">
                <div className="team-details__content">
                    <h2>R.Sridhar</h2>
                    <span>Advisor to the board​</span>
                    <p>R. Sridhar is a renowned finance leader with a distinguished career as the former Managing Director and CEO of Shriram Transport Finance Company Limited. His leadership was pivotal in the company's growth, and his influence extended as Executive Vice Chairman of IndoStar Capital and Senior Advisor to TPG in Mumbai.</p>
                </div>
                <br/>
                </div>
        </div>
        <div className='alignText'>
        <p>R. Sridhar has also served as a Director at Tata Motors Finance Limited and has been actively involved in several key committees. He was Chairman of the Finance Industry Development Council (FIDC), a member of the Corporate Bond and Securitisation Advisory Committee of SEBI, and the Advisory Group on NBFCs, constituted by the Ministry of Finance.</p>
        <p>His exceptional contributions have earned him notable accolades, including the “Excellence in Financial Reporting” award from ICAI and the “Outstanding CEO” Award from TPG. Ranked No. 6 among India’s top CEOs and No. 1 in total shareholder returns by BT-INSEAD-HBR, R. Sridhar is a respected fellow of the Institute of Chartered Accountants of India.</p>
        <br/>
        </div>
        </div>
        </section>
        </>
        )}

        export default Advisors