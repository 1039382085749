import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Menservisedeatils/First'

const ServiceDetails = () => {

  return (
    <>
        <Bredcom title={"Home"} subtitle={"Service Details"} common={"Service Details"}/>
        <First/>
    </>
  )
}

export default ServiceDetails