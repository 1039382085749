const ReadMore = () => {
    return (
        <div>
           <br/>
            <label>With a fresh approach and a commitment to excellence, we assembled a professional team to drive our vision forward. Our journey officially commenced in April 2023, and we marked this milestone by inaugurating our first branch at Thirukoviloor on the 24th of the same month. This was just the beginning as we rapidly expanded our footprint in 12 locations across Tamil Nadu strategically positioning ourselves to cater to the diverse needs of our valued customers.
            </label>
            <br/><br/>
            <label>In a remarkably short span of time, we are proud to announce that we have successfully acquired over 3000 customers, a testament to our dedication to providing accessible and reliable vehicle financing solutions. At Finergy Transport Finance Limited, we are not just a finance company; we are your trusted partner on the road to realizing your dreams.
</label>
        </div>
    )
}

export default ReadMore;