
import { Link}  from 'react-router-dom'
import React from 'react'

function First() {
    return (
        <>
            <section className="blog-page pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-1.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-2.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-3.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-4.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-5.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-30">
                            <div className="blog-card">
                                <div className="blog-card__image">
                                    <span>20 Sep</span>
                                    <img src="assets/images/blog/blog-1-6.png" alt="" />
                                </div>
                                <div className="blog-card__content">
                                    <div className="blog-card__meta">
                                        <Link to="#"><i className="fa fa-tags"></i>Loan</Link>
                                        <Link to="#"><i className="fa fa-comments"></i>2 Comments</Link>
                                    </div>
                                    <h3><Link to="/news-details">Changing & grow your business faster</Link></h3>
                                    <div className="blog-card__author">
                                        <img src="assets/images/blog/blog-author-1-1.png" alt="" />Christine Eve
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled post-pagination d-flex justify-content-center align-items-center">
                        <li><Link to="#"><i className="far fa-angle-left"></i></Link></li>
                        <li><Link to="#">01</Link></li>
                        <li><Link to="#">02</Link></li>
                        <li><Link to="#">03</Link></li>
                        <li><Link to="#"><i className="far fa-angle-right"></i></Link></li>
                    </ul>
                </div>
            </section>
        </>
    )
}

export default First