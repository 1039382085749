import { Link } from 'react-router-dom';

import React ,{ useState , useEffect } from 'react'

function Loans() {

    const [emiVal, setEmival] = useState()
    const [TotalVal, setTotalVal] = useState()
    const [slider1, setSlider1] = useState(1000)
    const [slider2, setSlider2] = useState(1)

    useEffect(() => {
        if (slider1 > 0 && slider2 > 0) {
            emiCalculate()
        }
    }, [slider1, slider2])

    const emiCalculate = () => {
        const interestRate = 15;
        const interestRatePercent = parseInt(interestRate, 10) / 100;
        const totalPay = slider1 * interestRatePercent + parseInt(slider1, 10);
        const monthlyPay = totalPay / parseInt(slider2,10);
        
        setEmival(monthlyPay)
        setTotalVal(totalPay)
    }

    return (
        <>
            <section className="about-one">
                <div className="container pb-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-one__content">
                                <div className="block-title text-left">
                                    <p>Company Introductions</p>
                                    {/* <h3>Our journey </h3> */}
                                </div>
                            { /*    <p className="about-one__text">Lorem ipsum is simply dolor sit amet, consectetur adipisicing text elit.</p> */}
                                {/* <p>We are a Non Banking Finance Company, Registered under Reserve Bank of India on 26th February 1996 having our Administrative office at Chennai,Tamil Nadu.​</p>  */}
                            <p>Finergy is a retail NBFC focusing in affordable finance solutions directed towards Semi-urban and Rural India. Our aim to service these under-serviced / unserved segments and enable a platform of financial products and convenience to our customers.  </p>
                            </div>
                        </div>


                        <div className="col-lg-6">
                            <div className="about-one__content">
                                <div className="block-title text-left">
                                   
                                    <p>Our key business guidelines revolve around 3Es- </p>
                                </div>
                            { /*    <p className="about-one__text">Lorem ipsum is simply dolor sit amet, consectetur adipisicing text elit.</p> */}
                                {/* <p>In Finergy Transport Finance Limited ,we have reinvented the business Strategy , appointed the professional team and commenced commercial vehicle business from the month of April 2023.
We opened our branch at Thirukoviloor on 24th April 2023, followed by more than 10 branches across Tamil Nadu.
We have acquired over 2000 Customers in a Short Span of time. 
​</p>  */}
<p>Enable – our customers with best-in-class financial products</p>
<p>Engage – with our customers on a Continuous basis.</p>
<p>Enrich – our customers lives.</p>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default Loans