import React from 'react'
import { Link } from 'react-router-dom'

const boxes = [
    {
        icon: 'pylon-icon-internet',
        title: 'Global Acceptability',
        description: 'There many variation of pasages of lorem sum available.'
    },
    {
        icon: 'pylon-icon-buying',
        title: 'Buy Now Pay Later',
        description: 'There many variation of pasages of lorem sum available.'
    },
    {
        icon: 'pylon-icon-online-payment',
        title: 'SMS & Internet Banking',
        description: 'There many variation of pasages of lorem sum available.'
    },
    {
        icon: 'pylon-icon-call-center',
        title: '24/7 Customer Support',
        description: 'There many variation of pasages of lorem sum available.'
    }
]

function Global() {
    return (
        <>
            <section className="feature-three">
                <div className="container">
                    <div className="row">
                        {boxes.map((box, index) => (
                            <div className="col-lg-3 col-md-6" key={index}>
                                <div className="feature-three__box">
                                    <div className="feature-three__box-inner">
                                        <i className={box.icon}></i>
                                        <h3><Link to="#">{box.title}</Link></h3>
                                        <p>{box.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Global
