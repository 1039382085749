import { Link } from 'react-router-dom';
import React from 'react';
import ReadMore from '../About/TeamDetails/ReadMore';
import { useState } from 'react'
import Location from '../Menhome/Location';

function First() {
  const [readMore, setReadMore] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  return (
    <>
<section className="team-details">
                <div className="container">
                    <div className="row ">
                        <div className='col-lg-6'>
                            <div className="block-title text-left">
                                <br />
                                <p>Get To Know About Us</p>
                                <h1>About Our Company</h1>
                            </div>
                        </div>
                        <div className=''>

                            <div className="team-details__content">
                                <label>We established ourselves as a Registered Non-Banking Finance Company, operating under the regulatory framework of the Reserve Bank of India on the 26th of February 1996. Our focus and expertise lie in the dynamic realm of vehicle finance, and our commitment to providing reliable and accessible financial solutions for your vehicle needs sets us apart in the industry. </label>
                                {!readMore && <a className='ReadMore' href onClick={() => setReadMore(!readMore)}> Read More </a>}
                                {readMore && <ReadMore />}
                                {readMore && <a className='ReadMore' href onClick={() => setReadMore(!readMore)}> Read Less </a>}

                            </div>
                        </div>
                    </div>
                    <br />

                    <div className="row ">
                        <div className='col-lg-6'>
                            <div className="block-title text-left">
                                <br />
                                <p>Here's what we do</p>
                                <h1>Business Model</h1>
                            </div>
                        </div>

                        <div className=" alignText ml-1">
                            <p>Finergy focuses on building a very strong customer-oriented approach, through our branch network spread across Tamilnadu for now, with the Vision to expand across the country in the coming years. We service all financial needs of the customer – Loans & Insurance.  </p>

                        </div>
                    </div>
                    <br />
                    <div className="row ">
                        <div className='col-lg-6'>
                            <div className="block-title text-left">
                                <br />
                                <p>What we've done so far</p>
                                <h1>Our Journey</h1>
                            </div>
                        </div>

                        <div className=" alignText ml-1">
                            <p>FINERGY TRANSPORT FINANCE LIMITED previously called TRANCITY FINANCE LIMITED, was incorporated on the 26th February 1996.</p>
                            <p>Trancity Finance Limited decided to reinvent its business approach by investing in a strong professional team, with many years of experience in the finance industry. With the new strategy commencing April 2023, new board members were inducted with the vision to run and manage and co-own the organization with new brand name – Finergy Transport Finance Limited. This has resulted in substantial growth and reach.</p>

                        </div>
                    </div>
                    <br />
                    </div>
                    </section>
                     <section >
                <div className='container'>
                    <div className="row ">
                        <div className='col-lg-6'>
                            <div className="block-title text-left">
                                <br />
                                <p>We have our branches at</p>
                                <h1>Our Presence</h1>
                            </div>
                        </div>

                        <div className=" alignText ml-1">
                            <p>With the Vision to provide financial services to under serviced customers, living in non-urban markets in quick turnaround time and easy processing steps, we aspire to be a pan India player soon. Currently we are present in the following locations</p>
                            <Location />
                            <br />
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}
export default First;
