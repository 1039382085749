import React from 'react'
import Bredcom from '../Bradcom/Main'
import Leave from '../Mencontact/Leave'
import Map from '../Mencontact/Map'

const Contact = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"Contact Us"} common={"Contact Us"}/>
        <Leave/>
        <Map/>
    </>
  )
}

export default Contact