import React from 'react'
import { Link } from 'react-router-dom'

function Visa() {
    return (
        <>
            <section className="about-two credit-card__details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft" data-wow-duration="1500ms">
                            <div className="about-two__image">
                                <img src="assets/images/credit-card/credit-card-d-1-1.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-two__content">
                                <div className="block-title text-left">
                                    <p>Visa Gold Card</p>
                                    <h2>Visa Gold Card, More Than Just a Card!</h2>
                                </div>
                                <p>There are many variations of passages of lorem ipsum available the majority have suffered alteration in some form by injected humour. Duis aute irure dolor lipsum is simply free text available in the local markets in reprehenderit.</p>
                                <ul className="list-unstyled about-two__list">
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Visa Gold Card Shopping Offers
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Rewards Points on International Spending
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        3% Cashback at Electronics Outlets
                                    </li>
                                    <li>
                                        <i className="fa fa-check-circle"></i>
                                        Purchase Fuel or Car Servicing at Any Fuel Outlet
                                    </li>
                                </ul>
                                <div className="button-wrap">
                                    <Link to="#" className="thm-btn">Apply For Card</Link>
                                    <div className="main-header__info-phone">
                                        <i className="pylon-icon-tech-support"></i>
                                        <div className="main-header__info-phone-content">
                                            <span>Call Anytime</span>
                                            <h3><Link to="tel:92-666-888-0000">92 666 888 0000</Link></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Visa