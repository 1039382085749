import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React  from 'react'
function Location() {
  return (
    <>
        <div className="client-carousel pt-50 pb-50 ">
        
            <div className="container">  
            {/* <div className="block-title text-center">
                        <h3>Existing  Offices / Locations</h3>
                    </div> */}
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:1500}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <h2>                        <a href="https://shorturl.at/grOU7" target="_blank" rel="noopener noreferrer">Chennai</a> 
</h2>
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>
                        <a href="https://shorturl.at/vDKN9" target="_blank" rel="noopener noreferrer">Namakkal</a>
                            </h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/grAX7" target="_blank" rel="noopener noreferrer">Villupuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/gqLX5" target="_blank" rel="noopener noreferrer">Thirukoviloor</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/cjmKQ" target="_blank" rel="noopener noreferrer">Kallakurichi</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/mowN4" target="_blank" rel="noopener noreferrer">Chinnasalem</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/bfiG4" target="_blank" rel="noopener noreferrer">Sankarapuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/vKXZ8" target="_blank" rel="noopener noreferrer">Manaloorpet</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/bqwY8" target="_blank" rel="noopener noreferrer">Ananthapuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/jkJ27" target="_blank" rel="noopener noreferrer">Rasipuram</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/jkJ27" target="_blank" rel="noopener noreferrer">Athur</a>
</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>                        <a href="https://shorturl.at/jkJ27" target="_blank" rel="noopener noreferrer">Panruti</a>
</h2> 
                        </div>
                        </SwiperSlide>
                       {/*  <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Sankarapuram</h2> 
                        </div>
                       </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide> */}
                    </div>
                </Swiper>
            </div>
        </div>
    </>
  )
}

export default Location