import React, { useState} from 'react'
import VBG1 from '../../../assets/images/backgrounds/video-bg-1-1.jpg'
import { Link}  from 'react-router-dom'

function Business() {
    const [ytShow, setytShow] = useState(false);
    return (
        <>
            <section className="video-two">
                <div className="video-two__bg" style={{ backgroundImage: `url(${VBG1})` }}></div>
                <div className="container">
                    <Link to="#" onClick={() => setytShow(true)} className="video-one__btn video-popup"><i className="fa fa-play"></i></Link>
                    <h3>We Can Give Best Facilities
                        For Business</h3>
                </div>
            </section>

            {ytShow &&
                <>
                    <div className="mfp-bg mfp-fade mfp-ready" style={{ height: '3378px', position: 'absolute' }}></div>
                    <div className="mfp-wrap mfp-close-btn-in mfp-auto-cursor mfp-fade mfp-ready" tabindex="-1" style={{ top: '900px', position: 'absolute', height: '590px' }}>
                        <div className="mfp-container mfp-s-ready mfp-iframe-holder"><div className="mfp-content"><div className="mfp-iframe-scaler">
                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={() => setytShow(false)}>×</button>
                            <iframe className="mfp-iframe" src="//www.youtube.com/embed/fKjTmTVqnG8?autoplay=1" frameborder="0" allowFullscreen="">
                            </iframe>
                        </div>
                        </div>
                        <div className="mfp-preloader">Loading...</div></div></div>
                </>}
        </>
    )
}

export default Business