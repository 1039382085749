import React from 'react'
import Bredcom from '../../Bradcom/Main'
import Quote from '../Menwork/Quote'
import Business from '../Menwork/Business'
import Process from '../Menwork/Process'
import Quickly from '../../Menhome/Quickly'

const Work = () => {
   
  return (
    <>
        <Bredcom title={"Home"} subtitle={"How It Works"} common={"How It Works"}/>
        <Quote/>
        <Business/>
        <Process/>
        <Quickly/>
    </>
  )
}

export default Work