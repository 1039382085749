import BGPH1 from '../../assets/images/main-slider/main-slider-1-1.jpeg'
import BGPH2 from '../../assets/images/main-slider/main-slider-1-2.jpeg'
import BGPH3 from '../../assets/images/main-slider/main-slider-2-1.jpeg'
import sample from '../../assets/images/main-slider/main-slider.jpg'
import sample1 from '../../assets/images/main-slider/sampl1.jpg';
import sample2 from '../../assets/images/main-slider/sample2.jpg';
import sample3 from '../../assets/images/main-slider/sample3.jpg';
import mainSlider from '../../assets/images/main-slider/main-slider-1-1.jpeg'
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link }  from 'react-router-dom'
import React  from 'react'
// import Main from '../Contact/Main'
import contact from '../Contact/Main'

function Sliderone() {
    return (
        <>
            <section className="main-slider" >
                <Swiper className="swiper-container thm-swiper__slider"
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        nextEl: "#main-slider__swiper-button-next",
                        prevEl: "#main-slider__swiper-button-prev"
                    }}
                    autoplay={{ delay: 4000 }}
                >
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH3})`}}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <br/>
                                            <br/>
                                            <h2 style={{fontSize:'50px'}} >Drives Your Dreams</h2>
                                            <Link to="contact" className=" thm-btn">Contact Us</Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${mainSlider})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-7 col-lg-6">
                                            {/* <p>Simple & Secure Payment Process</p> */}
                                            <h2 style={{fontSize:'50px'}} >Ready to turn your vehicle dreams into reality?</h2>
                                            <Link to="contact" className=" thm-btn">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${sample3})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-9 col-lg-6">
                                            {/* <p>Simple & Secure Payment Process</p> */}
                                            <h2 style={{fontSize:'50px'}} >Embark on a Hassle-Free Journey with Our Vehicle Loan Solutions!</h2>
                                            <Link to="contact" className=" thm-btn">Contact Us</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        {/* <SwiperSlide>
                            <div className="swiper-slide">
                                <div className="image-layer" style={{ backgroundImage: `url(${BGPH5})` }}></div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6">
                                            <p>Simple & Secure Payment Process</p>
                                            <h2>Connecting
                                                All Your Loan
                                                Needs</h2>
                                            <Link to="/contact" className=" thm-btn">Apply For Loan</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                    </div>


                    <div className="main-slider__nav">
                        <div className="swiper-button-prev" id="main-slider__swiper-button-next"><i className="pylon-icon-right-arrow"></i></div>
                        <div className="swiper-button-next" id="main-slider__swiper-button-prev"><i className="pylon-icon-left-arrow"></i></div>
                    </div>

                </Swiper>
                <div className="feature-two">
                    <div className="container">
                        <div className="row" style={{marginBottom:'20px'}} >
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms">
                                <div className="feature-two__box"  style={{marginTop:'-20px'}} >
                                    <i className="pylon-icon-consumer-behavior"></i>
                                    <p >Quick Payment
                                        Process</p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1500ms" >
                                <div className="feature-two__box"  style={{marginTop:'-20px'}} >
                                    <i className="pylon-icon-point-of-sale"></i>
                                    <p>No Processing
                                        Fees</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone