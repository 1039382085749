import React from 'react'


const services = [
    {
        imgSrc: "assets/images/services/twoLoan.jpeg",
        title: "Two-Wheeler Loans",
        description: "Embark on the journey to two-wheeler freedom with our swift and seamless quick loans tailored just for you! Whether you're eyeing the sleek lines of a brand-new ride or looking to unlock the hidden potential of your trusty used two-wheeler, we've got you covered.          "
    },
    {
        imgSrc: "assets/images/services/vehLoan1.jpeg",
        title: "Vehicle Loans",
        description: "Elevate your driving experience to new heights with our exclusive quick loans designed for both the thrill-seekers eyeing a brand-new four-wheeler and those looking to unlock the untapped potential of their trusty used cars.                                                 "
    },
    {
        imgSrc: "assets/images/services/smeLoan.jpg",
        title: "SME Loans",
        description: "Fuel the growth of your MSME or small business in the dynamic trading sector with our quick and efficient working capital loans! In the fast-paced world of commerce, where every opportunity is a heartbeat away, having the right financial support is the key to staying ahead. "
    }  
];

function First() {
    return (
        <>
            <section className="service-page pt-120 pb-60 serviceshight">
                <div className="container"  >
                    <p>Our products include two-wheeler loans, SME loans (Unsecured Personal Loans), Vehicle Loans, dedicated towards matching the evolving needs of people living in tier-2 and beyond</p>
                    <br/>
                    <div className="row card-height"  >
                        {services.map((service, index) => (
                            <div className="col-lg-4 col-md-6 mb-60" key={index}>
                                <div className="service-one__card">
                                    <div className="service-one__image">
                                        <img src={service.imgSrc} alt="" />
                                    </div>
                                    <div className="service-one__content">
                                          <h3>{service.title}</h3>         
                                        <p>{service.description}</p>
                                       
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
