
import React from 'react'
import Sliderone from '../Menhome/Sliderone'
import Loans from '../Menhome/Loans';
import Offering from '../Menhome/Offering';
import Flexible from '../Menhome/Flexible';
import Trusted from '../Menhome/Trusted';
import Counter from '../Menhome/Counter';
import Testimonials from '../Menhome/Testimonials';
import Why from '../Menhome/Why';
import News from '../Menhome/News';
import Clint from '../Menhome/Clint';
import Location from '../Menhome/Location';
import Quickly from '../Menhome/Quickly';
import Business from '../Menhome/Business';
import Slidertwo from '../Menhome/Slidertwo';


const Main = () => {
   
  return (
    <>
        <Sliderone/>
        <Loans/> 
        {/*<Offering/> */}
        <Flexible/>
       {/* <Trusted/> */}
        {/* <Counter/>  */}
      {/*<Testimonials/> */}
        <Why/>
       {/*<News/> */}
       {/* <Location/> */}
        {/* <Clint/>  */}
        <Business/>  
        <Slidertwo />
        <Quickly/>
    </>
  )
}

export default Main