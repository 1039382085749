import React from 'react'
import Bredcom from '../../Bradcom/Main'
import Visa from '../Mencreditdeatils/Visa'
import Global from '../Mencreditdeatils/Global'

const CreditCardDeatils = () => {
    return (
        <>
            <Bredcom title={"Home"} subtitle={"Express Card Details"} common={"Express Card Details"}/>
            <Visa/>
            <Global/>
        </>
    )
}

export default CreditCardDeatils