import React from 'react'
import { Link}  from 'react-router-dom'

function Leave() {
    return (
        <>
            <section className="contact-one">
                <div className="container">
                    <div className="block-title text-center">
                        <p>Contact With Us</p>
                        <h2>Leave a Message</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contact-one__content">
                                <div className="contact-one__box">
                                    <i className="pylon-icon-telephone"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Call Anytime</h4>
                                        <p>044-49793883</p>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-email1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Write Email</h4>
                                        <p>care@finergytransportfinancelimited.com</p>
                                    </div>
                                </div>
                                <div className="contact-one__box">
                                    <i className="pylon-icon-pin1"></i>
                                    <div className="contact-one__box-content">
                                        <h4>Visit Office</h4>
                                        <p style={{marginBottom:0}}> 74, 1st Floor, Sundaram St,</p>
                                        <p style={{marginBottom:0}}> Chinmaya Nagar Stage 1, Sundaram St, </p>
                                        <p style={{marginBottom:0}}>Koyambedu, Chennai, Tamil Nadu 600092</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <form className="contact-one__form">
                                <div className="row low-gutters">
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Your Name" name="name" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Your Email" name="email" required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Phone Number" name="phone" />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" placeholder="Subject" name="subject" />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="message" placeholder="Write Message"></textarea>
                                        <button className="thm-btn" type="submit">Send A Message</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Leave