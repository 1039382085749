import React from 'react'
import { Link } from 'react-router-dom'

const FEATURES = [
  {
    icon: 'pylon-icon-point-of-sale',
    title: 'Get Quote',
    description: 'There many variation of pasages of lorem sum available.',
    link: '#',
  },
  {
    icon: 'pylon-icon-consumer-behavior',
    title: 'Meet Agent',
    description: 'There many variation of pasages of lorem sum available.',
    link: '#',
  },
  {
    icon: 'pylon-icon-assets',
    title: 'Quick Decision',
    description: 'There many variation of pasages of lorem sum available.',
    link: '#',
  },
  {
    icon: 'pylon-icon-investment',
    title: 'Get Funding',
    description: 'There many variation of pasages of lorem sum available.',
    link: '#',
  },
];

function Quote() {
  return (
    <section className="feature-three">
      <div className="container">
        <div className="row">
          {FEATURES.map((feature) => (
            <div key={feature.title} className="col-lg-3 col-md-6">
              <div className="feature-three__box">
                <div className="feature-three__box-inner">
                  <i className={feature.icon}></i>
                  <h3><Link to={feature.link}>{feature.title}</Link></h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Quote;
