import React from 'react'

function Map() {
    return (
        <>
            <div className="google-map__home-two">
                <iframe title="template google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.593093081084!2d80.19862945!3d13.06155285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266b0a345bc2b%3A0x8f072ee8513be7e!2sChinmaya%20Nagar%20Stage%201%2C%20Virugambakkam%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1699117575140!5m2!1sen!2sin" className="map__home-two" allowFullscreen></iframe>
            </div>
        </>
    )
}
export default Map