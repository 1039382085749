import { Link}  from 'react-router-dom'
import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Mennewsdeatils/First'

const NewsDetails = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"News Details"} common={"News Details"}/>
        <First/>
    </>
  )
}

export default NewsDetails