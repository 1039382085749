import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Mentest/First'

const Testimonial = () => {
  return (
    <>
        <Bredcom title={"Home"} subtitle={"Testimonials"} common={"Testimonials"}/>
        <First/>
    </>
  )
}

export default Testimonial