import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React  from 'react'

function Clint() {
  return (
    <>
        <div className="client-carousel  pt-50 pb-50 client-carousel__has-border-top">
        
            <div className="container">  
            <div className="block-title text-center">
                        <h3>Proposed Locations</h3>
                    </div>
                <Swiper className="thm-swiper__slider swiper-container" 
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    loop={true}
                    autoplay={{delay:3000}}
                    breakpoints={{
                        "376": {
                            "spaceBetween": 30,
                            "slidesPerView": 2
                        },
                        "576": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "768": {
                            "spaceBetween": 30,
                            "slidesPerView": 3
                        },
                        "992": {
                            "spaceBetween": 30,
                            "slidesPerView": 4
                        },
                        "1200": {
                            "spaceBetween": 30,
                            "slidesPerView": 5
                        }
                    }}>
                    <div className="swiper-wrapper">
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Tiruvannamalai</h2>    
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Arani</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Athur</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Coimbatore</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Salem</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Pollachi</h2> 
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Madurai</h2> 
                        </div>
                       </SwiperSlide>
                       <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Tirunelveli</h2> 
                        </div>
                       </SwiperSlide>
                       <SwiperSlide>
                        <div className="swiper-slide">
                        <h2>Erode</h2> 
                        </div>
                       </SwiperSlide>
                       {/*  <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide>
                        <SwiperSlide>
                        <div className="swiper-slide">
                            <img src="assets/images/resources/brand-1-1.png" alt="" />
                        </div>
                        </SwiperSlide> */}
                    </div>
                </Swiper>
            </div>
        </div>
    </>
  )
}

export default Clint