import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const data = [
  { id: 0, value: 20, label: 'Regulatory Compliance', data1: 10,data2: 20},
  { id: 1, value: 20, label: 'Customer experience', data1: 10,data2: 20 },
  { id: 2, value: 20, label: 'Tailored Solutions', data1: 10,data2: 20 },
  { id: 3, value: 20, label: 'Quick and Easy Approval', data1: 10,data2: 20 },
  { id: 4, value: 20, label: 'Customer-Centric Approach', data1: 10,data2: 20 },
];

function getTooltipContent(slice) {
  console.log("entered");
  return (
    <>
      <p><b>{slice.label}</b></p>
      <p>Value: {slice.value}</p>
      <p>Data 1: {slice.data.data1}</p>
      <p>Data 2: {slice.data.data2}</p>
    </>
  );
}

export default function PieActiveArc() {
  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      interactions={[
        { type: 'hover', enabled: true }, // Ensure hover interaction is enabled
      ]}
      tooltip={{
        content: (event, context) => {
          const slice = context.data[event.dataIndex]; // Access slice data
          console.log(slice); // Test data access (remove after verification)
          return (
            <>
              <p><b>{slice.label}</b></p>
              <p>Value: {slice.value}</p>
              <p>Data 1: {slice.data.data1}</p>
              <p>Data 2: {slice.data.data2}</p>
            </>
          );
        },
      }}
     
      options={{
        plugins: {
          legend: {
            display: false, // Optional: Hide legend if not needed
          },
        },
        elements: {
          arc: {
            // Customize hover behavior for arcs
            hover: {
              animation: {
                duration: 100, // Optional animation duration
              },
              // Highlight hovered slice with desired styles
              options: (arc) => ({
                backgroundColor: 'lightblue', // Adjust highlight color
                borderColor: 'black', // Adjust highlight border color
              }),
            },
          },
        },
      }}
      height={200}
    />
  );
}
// import * as React from 'react';
// import { PieChart, arcLabelsGenerator } from '@mui/x-charts/PieChart';

// const data = [
//   { id: 0, value: 20, label: 'Regulatory Compliance', data1: 10, data2: 20 },
//   { id: 1, value: 20, label: 'Customer experience', data1: 10, data2: 20 },
//   { id: 2, value: 20, label: 'Tailored Solutions', data1: 10, data2: 20 },
//   { id: 3, value: 20, label: 'Quick and Easy Approval', data1: 10, data2: 20 },
//   { id: 4, value: 20, label: 'Customer-Centric Approach', data1: 10, data2: 20 },
// ];

// const customArcLabels = arcLabelsGenerator({
//   label: (arc) => `${arc.data.label} - ${arc.data.value}%`, // Customized label format
// });

// export default function PieActiveArc() {
//   return (
//     <PieChart
//       data={data}
//       options={{
//         elements: {
//           arc: {
//             borderWidth: 2, // Optional border for slices
//             hover: {
//               animation: {
//                 duration: 200, // Adjust animation duration for hover
//               },
//               options: (arc) => ({
//                 backgroundColor: 'lightblue', // Adjust highlight color
//                 borderColor: 'black', // Adjust highlight border color
//                 // Increase radius by a specific amount on hover
//                 radius: arc.outerRadius + 10,
//               }),
//             },
//           },
//         },
//         plugins: {
//           legend: {
//             display: false, // Optional: Hide legend if not needed
//           },
//           tooltip: {
//             enabled: true, // Ensure hover tooltip is enabled
//             callbacks: {
//               title: (tooltipItem) => tooltipItem.dataIndex, // Display slice index as tooltip title
//               label: (tooltipItem) => ( // Custom tooltip content
//                 <>
//                   <p><b>{tooltipItem.data.label}</b></p>
//                   <p>Value: {tooltipItem.data.value}</p>
//                   <p>Data 1: {tooltipItem.data.data1}</p>
//                   <p>Data 2: {tooltipItem.data.data2}</p>
//                 </>
//               ),
//             },
//           },
//         },
//       }}
//       arcLabel={customArcLabels}
//       interactions={[
//         { type: 'hover', enabled: true }, // Ensure hover interaction is enabled
//       ]}
//       height={300} // Adjust chart height as needed
//     />
//   );
// }
