import { Link } from 'react-router-dom';
import React from 'react';

function News() {
  const blogCards = [
    {
      date: '20 Sep',
      image: 'assets/images/blog/blog-1-1.png',
      tags: ['Loan'],
      comments: '2 Comments',
      title: 'Changing & grow your business faster',
      authorImage: 'assets/images/blog/blog-author-1-1.png',
      authorName: 'Christine Eve',
    },
    {
      date: '20 Sep',
      image: 'assets/images/blog/blog-1-2.png',
      tags: ['Loan'],
      comments: '2 Comments',
      title: 'Changing & grow your business faster',
      authorImage: 'assets/images/blog/blog-author-1-2.png',
      authorName: 'Christine Eve',
    },
    {
      date: '20 Sep',
      image: 'assets/images/blog/blog-1-3.png',
      tags: ['Loan'],
      comments: '2 Comments',
      title: 'Changing & grow your business faster',
      authorImage: 'assets/images/blog/blog-author-1-3.png',
      authorName: 'Christine Eve',
    },
  ];

  return (
    <>
      <section className="blog-home pt-120 pb-90">
        <div className="container">
          <div className="block-title text-center">
            <p>Directly From the Blog</p>
            <h2>News & Articles</h2>
          </div>
          <div className="row">
            {blogCards.map((blogCard, index) => (
              <div
                className="col-lg-4 wow fadeInUp"
                data-wow-duration="1500ms"
                key={index}
              >
                <div className="blog-card">
                  <div className="blog-card__image">
                    <span>{blogCard.date}</span>
                    <img src={blogCard.image} alt="" />
                  </div>
                  <div className="blog-card__content">
                    <div className="blog-card__meta">
                      {blogCard.tags.map((tag, index) => (
                        <Link to="#" key={index}>
                          <i className="fa fa-tags"></i>
                          {tag}
                        </Link>
                      ))}
                      <Link to="#">
                        <i className="fa fa-comments"></i>
                        {blogCard.comments}
                      </Link>
                    </div>
                    <h3>
                      <Link to="/newsdetails">{blogCard.title}</Link>
                    </h3>
                    <div className="blog-card__author">
                      <img src={blogCard.authorImage} alt="" />
                      {blogCard.authorName}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
