import React from 'react'
import Bredcom from '../../Bradcom/Main'
import Lower from '../Menfaq/Lower'

const FAQ = () => {
    return (
        <>
            <Bredcom title={"Home"} subtitle={"FAQ's"} common={"FAQ's"}/>
            <Lower/>
        </>
    )
}

export default FAQ