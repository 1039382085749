import React from 'react'

function Process() {
    return (
        <>
            <section className="how-works">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <img src="assets/images/resources/how-works.jpg" className="how-works__image" alt="" />
                        </div>
                        <div className="col-lg-7">
                            <div className="how-works__content wow fadeInRight" data-wow-duration="1500ms">
                                <div className="block-title text-left">
                                    <p>How Process Works</p>
                                    <h2>A Quick Payment Transparent Process</h2>
                                </div>
                                <p className="block-text">There are many variations of pass of lorem ipsum but the majority have suffered alteration in some form. Injected humour randomised words which.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Process