import React from 'react'
import Headertwo from '../Mehhometwo/Headertwo'
import Slidertwo from '../Mehhometwo/Slidertwo'
import Quick from '../Mehhometwo/Quick'
import Transparent from '../Mehhometwo/Transparent'
import Quicklytwo from '../Mehhometwo/Quicklytwo';
import Offeringtwo from '../Mehhometwo/Offeringtwo';
import Video from '../Mehhometwo/Video';
import Business from '../Mehhometwo/Business';
import Professional from '../Mehhometwo/Professional';
import News from '../Menhome/News';
import Map from '../Mehhometwo/Map';
import Clint from '../Menhome/Clint';

const Home2 = () => {

  return (
    <>
        <Headertwo/>
        <Slidertwo/>
        <Quick/>
        <Transparent/>
        <Quicklytwo/>
        <Offeringtwo/>
        <Video/>
        <Business/>
        <Professional/>
        <News/>
        <Map/>
        <Clint/>
    </>
  )
}

export default Home2