import React from 'react'
import Bredcom from '../../Bradcom/Main'
import First from '../Menloan/First'

const LoanCalculator = () => {

  return (
    <>
        <Bredcom title={"Home"} subtitle={"Loan Calculator"} common={"Loan Calculator"}/>
        <First/>
    </>
  )
}

export default LoanCalculator