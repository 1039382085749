import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ReadMore from '../About/TeamDetails/ReadMore';
import im1 from '../../assets/images/team/team-1-1.png'
import gowthaman from '../../assets/images/team/gowthaman.jpeg'
import sridhar from '../../assets/images/team/sridhar1.png'
import rajanBabu from '../../assets/images/team/RajanBabu.jpeg'
import Lakshmi from '../../assets/images/team/Lakshmi.jpg'
import { SwiperSlide } from 'swiper/react';
import Location from '../Menhome/Location';
import MyAccordion from './MyAccordion';
import { red } from '@mui/material/colors';

function First() {

 
    // const team1 = [
    //     {
    //         imgSrc: "assets/images/team/9.jpg",
    //         title: "K Jayalakshmi",
    //         description: 'After successful completion of ACS she has had an impactful career spanning over 6 years immensely contributing to her clientile in areas of company law and compliance. She has worked with multiple startups and MSMEs.'
    //     },
    //     {
    //         imgSrc: "assets/images/team/7.jpg",
    //         title: "Balaji P",
    //         description: 'Carries over 15 Years of Finance Experience in Vehicle Finance space. Was earlier associated with Shriram Finance, Cholamandalam Finance and Indostar Capital.'
    //     },
    //     {
    //         imgSrc: "assets/images/team/8.jpg",
    //         title: "R Settu",
    //         description: "Carries over 15 years of Finance Experience in Vehicle finance space. Was earlier associated with Shriram Finance."
    //     },
    //     {
    //         imgSrc: "assets/images/team/10.jpg",
    //         title: "J Venkatesh",
    //         description: "Carries over 10 years of experience in Loan Originating System  and Loan Mgt System. He was earlier associated with Equitas Small Finance Ltd and Indostar Capital Finance Ltd."
    //     },
    //     {
    //         imgSrc: "assets/images/team/4.jpg",
    //         title: "N Uma",
    //         description: 'Highly experienced vehicle finance professional with 15+ years in branch management, disbursement, HR,  and recovery. Dedicated to leveraging this knowledge for impactful contributions.',
    //         // desc: "Bringing over 15 years of extensive finance experience within the vehicle finance sector, has previously held roles at Shriram Finance and Indostar Capital. Her tenure spans a diverse range of responsibilities, including branch administration, loan disbursement, HR coordination, cash management, recovery management, and dedicated to leveraging this wealth of experience to drive success and innovation in every aspect."
    //     }

    // ];
    // const team = [{
    //     imgSrc: "assets/images/team/10.jpg",
    //     title: "J Venkatesh",
    //     description: "Carries over 10 years of experience in Loan Originating System  and Loan Mgt System. He was earlier associated with Equitas Small Finance Ltd and Indostar Capital Finance Ltd."
    // },
    // {
    //     imgSrc: "assets/images/team/4.jpg",
    //     title: "N Uma",
    //     description: 'Highly experienced vehicle finance professional with 15+ years in branch management, disbursement, HR,  and recovery. Dedicated to leveraging this knowledge for impactful contributions.',
    //     // desc: "Bringing over 15 years of extensive finance experience within the vehicle finance sector, has previously held roles at Shriram Finance and Indostar Capital. Her tenure spans a diverse range of responsibilities, including branch administration, loan disbursement, HR coordination, cash management, recovery management, and dedicated to leveraging this wealth of experience to drive success and innovation in every aspect."
    // }];

    return (
        <>
            <section className="team-details">
                <div className="container">
                   
                    <div className='col-lg-6'>
                        <div className="block-title text-left">
                            <h1>Meet our Team</h1>
                            <p>Directors</p>
                        </div>
                    </div>
                    <div className="row" id='person1'>
                        <div className="col-lg-5">
                            <img src={gowthaman} width="70%" height="300px" className="img-fluid rounded-image" alt="" />
                        </div>

                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>A Gowthaman</h2>
                                <span>Managing Director​</span>
                                <p>A Gowthaman is a distinguished professional with an extensive and illustrious career spanning over 25 years in the Vehicle Finance sector. He graduated from National Institute of Technology, Trichy, with a degree in management. His remarkable journey started as a branch manager in Shriram Transport Finance Ltd going on to head the national business for commercial vehicles. Subsequently as a President/Founder Business Head at Indostar Capital, he was instrumental in successfully setting up the commercial vehicle business across the country.</p>
                            </div>
                        </div>
                        <div>
                            <br />
                            <div className='alignText'>

                                <p>A Gowthaman embarked on a significant venture by starting the business operations of M/s Indostar Capital in India. In his role as President/Founder Business Head, he played a key role in establishing a robust presence, setting up an impressive network of over 200 branches across the country. His strategic acumen and leadership were instrumental in managing a substantial business portfolio exceeding Rs 5000 Crore.</p>
                                <p>Gowthaman's wealth of experience, coupled with his visionary leadership, has not only contributed to the growth and success of the organizations he has been associated with but has also left an indelible mark on the Vehicle Finance industry as a whole. His ability to navigate complex financial landscapes and drive business expansion underscores his status as a respected and influential figure in the sector.</p>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row" id='person2'>
                        <div className="col-lg-5">
                            <img src={sridhar} width="70%" height="300px" className="img-fluid rounded-image" alt="" />
                        </div>

                        <div className="col-lg-6">
                            <div className="team-details__content">
                                <h2>C.Sridhar</h2>
                                <span>Director​</span>
                                <p>C. Sridhar IPS Inspector General of Police Retd served in Indian Police Service around 30 years and served across various parts of Tamil Nadu in various posts. After retirement he turned into an accomplished entrepreneur overseeing diverse business ventures, including S2 Organic Foods India, dedicated to providing consumers with premium organic food products. Additionally, he leads AMS Projects, focusing on the development of essential infrastructure projects within the country, among other enterprises.</p>
                            </div>
                        </div>
                        <div>
                            <br/>

                            <p>Post 30 years in Indian Police Service he retired as an Inspector General of Police, he set his vision to create businesses with social impact.</p>
                        </div>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className="row" id='person3'>
                            <div className="col-lg-5">
                                <img src={Lakshmi} width="70%" height="300px" className="img-fluid rounded-image" alt="" />
                            </div>

                            <div className="col-lg-6">
                                <div className="team-details__content">
                                    <h2>Lakshmi T</h2>
                                    <span>Director​</span>
                                    <p>Lakshmi T has been a driving force from our initial days as Transcity finance and continue to play a critical role in providing her valuable experience in Finergy, follows her passion of social upliftment of under privileged by providing affordable education to many kids in her school at Namakkal.She is an avid sport enthusiast and runs a Tennis academy & multiple sport centres.</p>
                                </div>
                            </div>
                            <div>
                                <br />

                            </div>
                        </div>
                        <br />
                        <br />
                        <div className="row" id='person4'>
                            <div className="col-lg-5">
                                <img src={rajanBabu} width="70%" height="300px" className="img-fluid rounded-image" alt="" />
                            </div>

                            <div className="col-lg-6">
                                <div className="team-details__content">
                                    <h2>Rajan Babu</h2>
                                    <span>Independent Director</span>
                                    <p>Rajan Babu boasts over 35 years of diverse expertise in Banking Operations, Microfinance Activities, and Rural Development, with a distinct focus on poverty alleviation for marginalized communities. Serving as the National Mission Manager for the Financial Inclusion Team under the National Rural Livelihood Mission, he played a pivotal role in a groundbreaking initiative, offering skill development and job placement to 50,000 rural youths in prominent corporates</p>
                                </div>
                            </div>
                            <div>
                                <br />
                                <div className='alignText'>

                                    <p>His significant contributions extend beyond his operational roles, with authorship of influential publications like the "SHG Bank Linkage Handbook" and "Poverty to Prosperity Handbook." Babu's decade-long collaboration with the Tamil Nadu Government involved key positions in the Rural Development Department, closely engaging with over 500 NGOs to drive socio-economic development.</p>
                                    <p>He is recognized as an Outstanding Performer in the World Bank-assisted TNEPRP. Currently serving as an integral member of the Advisory Committee on credit information and NABARD Advisory Committee on Strategies Development for SHG Bank credit, Rajan Babu's enduring dedication underscores a remarkable career committed to inclusive development and poverty eradication.</p>
                                </div>                        </div>

                        </div>
                    </div>

                </div>
            </section>

            {/* <section className="service-page pt-120 pb-30 serviceshight carddd">
                <div className="container"  >
                    <div className='col-lg-6'>
                        <div className="block-title text-left">
                            <h1>Meet our Team</h1>
                            <p>Core Team</p>
                        </div>
                    </div>
                    <div className="row carddd">
                        {team1.map((team1, index) => (
                            <div className="col-lg-4 col-md-6 mb-60" key={index}>
                                <div className="service-one__card">
                                    <div className="team-one__image">
                                        <img src={team1.imgSrc} className="team-one__image" />
                                    </div>
                                    <div className="service-one__content">
                                        <h3>{team1.title}</h3>
                                        <p>{team1.description}</p>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </section> */}
            {/* <section className="service-page  pb-30 serviceshight">
            <div className="container"  >
            <div className="row ">
                        {team.map((team, index) => (
                            <div className="col-lg-4 col-md-6 mb-60" key={index}>
                                <div className="service-one__card">
                                    <div className="team-one__image">
                                        <img src={team.imgSrc} alt="" />
                                    </div>
                                    <div className="service-one__content">
                                          <h3>{team.title}</h3>         
                                        <p>{team.description}</p>
                                       
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
                

                </section> */}
           


            {/* 
           <section>
                <div className="container mt-5">
                    <div className="row">
                       <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div>
                        <div >
                            <div className="team-details__content">
                                <h2>R Settu </h2>
                                <p>Carries over 15 years of Finance Experience in Vehicle finance space. Was earlier associated with Shriram Finance.
                                    </p>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <section >
                <div className="container mt-5">
                    <div className='row'>
                       <div className="col-lg-6">
                            <img src="assets/images/team/team-1.jpg" width="80%" className="img-fluid" alt="" />
                            </div>
                        <div >
                            <div className="team-details__content">
                                <h2>N Uma </h2>
                                <span>Chief Manager​</span>
<p>Bringing over 15 years of extensive finance experience within the vehicle finance sector, has previously held roles at renowned institutions such as Shriram Finance and Indostar Capital. Her tenure spans a diverse range of responsibilities, including branch administration, loan disbursement, HR coordination, cash management, recovery management, and dedicated to leveraging this wealth of experience to drive success and innovation in every aspect</p>
                               <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Loan Process</h3>
                                        <span>90%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '90%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="why-choose__progress">
                                    <div className="why-choose__progress-top">
                                        <h3>Consultancy</h3>
                                        <span>76%</span>
                                    </div>
                                    <div className="why-choose__progress-bar">
                                        <span style={{ width: '76%' }} className="wow slideInLeft" data-wow-duration="1500ms"></span>
                                    </div>
                                </div>
                                <div className="team-details__certificates">
                                    <img src="assets/images/team/team-certificate-1-1.jpg" alt="" />
                                    <img src="assets/images/team/team-certificate-1-2.jpg" alt="" />
                                </div>
                                <div className="team-details__social">
                                    <Link to="#" className="fab fa-facebook-square"></Link>
                                    <Link to="#" className="fab fa-twitter"></Link>
                                    <Link to="#" className="fab fa-pinterest-p"></Link>
                                    <Link to="#" className="fab fa-instagram"></Link>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


        </>
    )
}

export default First