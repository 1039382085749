import { Link}  from 'react-router-dom'
import React from 'react'
import finergyLogo from '../../assets/images/finergyLogo.png';
const Footer = () => {
  return (
    <>
        <footer  className="site-footer">
            <div  className="container">
                <div  className="row flex ">
                    <div  className="col-lg-4 col-md-6 ">
                        
                        <div  className="footer-widget footer-widget__about">
                            < Link to="/" className=''>
                                <img src={finergyLogo} width='230' alt=""/>
                                {/* <h5 className='title'>FINERGY TRANSPORT FINANCE LIMITED</h5> */}
                            </Link>
                            <p></p>
                            <div  className="footer-widget__about-phone">
                                <i  className="pylon-icon-tech-support"></i>
                                <div  className="footer-widget__about-phone-content">
                                    <h3>< Link to="tel:044-49793883">044-49793883</Link></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  className="col-lg-3 col-md-1 ">
                        <div  className="footer-widget footer-widget__link">
                            <h3  className="footer-widget__title">Explore</h3>
                            <ul  className="list-unstyled footer-widget__link-list">
                                <li>< Link to="/team-details">About</Link></li>
                                <li>< Link to="/service">Our Products</Link></li>
                                <li>< Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div  className="col-lg-5 col-md-7 ">
                        <div  className="footer-widget footer-widget__contact">
                            <h3>Contact</h3>
                            <ul  className="list-unstyled footer-widget__contact-list">
                                <li>
                                    < Link to="#"><i  className="pylon-icon-email1"></i>care@finergytransportfinancelimited.com</Link>
                                </li>
                                <li>
                                    < Link to="#"><i  className="pylon-icon-clock2"></i>Mon - Sat 9:30 AM - 5:30 PM</Link>
                                </li>
                                <li>
                                    < Link to="#"><i  className="pylon-icon-pin1"></i>74, 1st Floor, Sundaram St, Chinmaya Nagar, Chinmaya Nagar Stage 1, Koyambedu, Chennai, Tamil Nadu 600092</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        {/* <Link to="#" data-target="html" className="scroll-to-target scroll-to-top"><i className="fa fa-angle-up"></i></Link> */}
    </>
  )
}

export default Footer